import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Üdvözöljük a Perk Haven Caféban
			</title>
			<meta name={"description"} content={"Kényeztesse magát egy tökéletes ital melegével - Perk Haven Café, ahol minden csésze egy történetet mesél el.\n"} />
			<meta property={"og:title"} content={"Üdvözöljük a Perk Haven Caféban"} />
			<meta property={"og:description"} content={"Kényeztesse magát egy tökéletes ital melegével - Perk Haven Café, ahol minden csésze egy történetet mesél el.\n"} />
			<meta property={"og:image"} content={"https://vorides.live/img/image-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vorides.live/img/135275.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vorides.live/img/135275.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vorides.live/img/135275.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://vorides.live/img/image-8.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="620px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Felveszi a kapcsolatot
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Kapcsolatfelvétel
				</Text>
				<Text font="--lead">
					- Cím: Budapest, 1015 Magyarország, Széna tér 1/a
					<br />
					<br />
					- Telefon: +36 70 425 7145
					<br />
					<br />
					- Email: contact@vorides.live
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});